export const module = {
  info: {
    namespace: 'App',
    name: 'App',
    caption: 'App',
  },
  vuexModules: ['state'],
  noRoutes: true,
  onLoad: async () => {

    $services['gDialog'] = {
      open : function (title, text) {
        $store.state['App-App-state'].generalDialogStatus = true
        $store.state['App-App-state'].generalDialogTitle = text
        $store.state['App-App-state'].generalDialogMessage = title
      },
      close : function (title, text) {
        $store.state['App-App-state'].generalDialogStatus = false
        $store.state['App-App-state'].generalDialogTitle = '-'
        $store.state['App-App-state'].generalDialogMessage = '-'
      }
    }

    $services['qDialog'] = {
      open : function (title, msg, onConfirm = () => {}, onClose = () => {}) {
        $store.state['App-App-state'].questionDialogStatus = true
        $store.state['App-App-state'].questionDialogTitle = title
        $store.state['App-App-state'].questionDialogMessage = msg
        $store.state['App-App-state'].questionDialogOnConform = onConfirm
        $store.state['App-App-state'].questionDialogOnCancel = onClose
      },
      close : function (title, text) {
        $store.state['App-App-state'].questionDialogStatus = false
        $store.state['App-App-state'].questionDialogTitle = ''
        $store.state['App-App-state'].questionDialogMessage = ''
      }
    }

    return 0
  }
}

export default module
